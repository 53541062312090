@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Creepster&family=Mountains+of+Christmas:wght@400;700&display=swap');

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}
body {
  background: #f1f1f1;
  /* overflow: hidden; */
  position: relative;
}

/***************************

BEGIN OF FLYING SANTA

***************************/
.santa {
  /* width: 10vw; */
  width: 25vw;
  height: 50vh;
  transform: rotate(180deg) scaleX(-1);
  z-index: 10;
  top: 0%;
  right: 0%;
  position: fixed;
}

.pumpkin {
  width: 3vw;
  height: 5vh;
}

.welcome {
  font-family: 'Creepster', system-ui;
  font-weight: 400;
  color: red;
  display: inline;
  font-style: normal;
  margin-left: 13%;
  font-size: 2.8vw;
}

.ghost {
  width: 10vw;
  height: 15vh;
}

@keyframes FlyingSanta {
  25% {
    bottom: 80%;
    left: 85%;
    transform: rotateY(0deg);
  }
  26% {
    transform: rotateY(180deg);
  }
  50% {
    bottom: 60%;
    left: 0%;
    transform: rotateY(180deg);
  }
  51% {
    transform: rotateY(0deg);
  }
  75% {
    bottom: 40%;
    left: 85%;
    transform: rotateY(0deg);
  }
  76% {
    bottom: 40%;
    left: 85%;
    transform: rotateY(180deg);
  }
  99% {
    transform: rotateY(180deg);
  }
}

/***************************

//// END OF FLYING SANTA ///

***************************/

.NorthPole {
  z-index: 500;
  width: 10vw;
  min-width: 180px;
}
.floating {
  -webkit-animation-name: Floating;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
}

@-webkit-keyframes Floating {
  from {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 30px);
  }
  to {
    transform: translate(0, -0px);
  }
}

/***************************

Clip Text for Festive Sparkles

***************************/

/* h1 {
  font-size: 6.5vw;
  padding-top: 60px;
  font-family: 'Mountains of Christmas', cursive;
  background: -o-linear-gradient(transparent, transparent) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  margin: 0;
  padding: 0;
  position: absolute;
  font-weight: 900;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  z-index: 200;
  -webkit-background-clip: text;
} */

.Absolute-Center {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/***************************
****************************
Text Glitter Styles
****************************
***************************/
.Gold-Glitter {
  color: gold;
  background: -webkit-linear-gradient(transparent, transparent),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/gold_glitter.gif) repeat;
}
.Blue-Glitter {
  color: blue;
  background: -webkit-linear-gradient(transparent, transparent),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/sparklesskyblue.gif) repeat;
}
.HotPink-Glitter {
  color: blue;
  background: -webkit-linear-gradient(transparent, transparent),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/sparkleshotpink.gif) repeat;
}
.Pink-Glitter {
  color: pink;
  background: -webkit-linear-gradient(transparent, transparent),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/pink_glitter.gif) repeat;
}
.Green-Glitter {
  color: green;
  background: -webkit-linear-gradient(transparent, transparent),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/green_glitter.gif) repeat;
}

/* #snow {
  background: none;
  z-index: 200;
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/flake1.png'),
    url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/flake2.png'),
    url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/191814/flake3.png');
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-animation: snow 10s linear infinite;
  -moz-animation: snow 10s linear infinite;
  -ms-animation: snow 10s linear infinite;
  animation: snow 10s linear infinite;
} */
@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }
  100% {
    background-position: 500px 1000px, 200px 400px, -100px 300px;
  }
}
@-moz-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }
  100% {
    background-position: 400px 1000px, 200px 400px, 100px 300px;
  }
}
@-webkit-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }
  100% {
    background-position: 500px 1000px, 200px 400px, -100px 300px;
  }
}
@-ms-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }
  100% {
    background-position: 500px 1000px, 200px 400px, -100px 300px;
  }
}

#canvasChristmas {
  display: block;
}

/* customizable snowflake styling */
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}
.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}
.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}
.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}
.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}
.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}
